import { acceptHMRUpdate, defineStore } from "pinia";
import { ref, computed, watch } from "vue";

import { useUserSession } from "/@src/stores/userSession";
import { Place } from "/@src/models/place";
import { useCollection } from "/@src/lib/stores/firestore";

export type UserData = Record<string, any> | null;

export const usePlaceManager = defineStore("placeManager", () => {
    const userSession = useUserSession();
    const user = userSession.getUser();
    const current = ref(null);
    const availables = useCollection(Place, {
        wheres: computed(() => {
            return [["owners", "array-contains", user.value && user.value.uid]];
        }),
        limit: 1,
    });
    watch(availables, () => {
        if (!current.value) {
            current.value = availables[0];
        }
    });

    return {
        current,
    };
});

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePlaceManager, import.meta.hot));
}
