<script lang="ts">
import { usePlaceManager } from "/@src/stores/placeManager";
import { Map } from "/@src/models/map";
import { toRefs, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export { Map as Model };

export function onBeforeCreate(entity: Map) {
    const placeManger: any = usePlaceManager();
    if (!placeManger.current) throw new Error("No place");
    entity.place = placeManger.current;
}

export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { t } = useI18n();
        const { form: map } = toRefs(props);
        return { t, map };
    },
});
</script>

<template>
    <VFieldModel v-model="map" property="name"></VFieldModel>
</template>
