import { getApp } from "./entry-client";

import { Place } from "./models/place";
import { Map } from "./models/map";
import { SocialTemplate } from "./models/socialTemplate";
import { SocialProfile } from "./models/socialProfile";
import { Customer } from "./models/customer";

import * as ComponentPlace from "./components/models/place.vue";
import * as ComponentMap from "./components/models/map.vue";
import * as ComponentSocialTemplate from "./components/models/socialTemplate.vue";
import * as ComponentCustomer from "./components/models/customer.vue";

import ComponentVModel from "./lib/components/model/VModel.vue";
import { useModal } from "/@src/lib/stores/modal";
import VButton from "./lib/components/base/button/VButton.vue";
import { ref, reactive } from "vue";
import { until } from "@vueuse/core";
import { lowerCaseFirst } from "./lib/stores/text";

function extend(Model: any, ModelComponentDeclaration?: any) {
    if (ModelComponentDeclaration)
        Model.addMethod("edit", async function (this: any) {
            const { app } = await getApp();
            const isEdit = this.$metadata.reference;
            const modelName = Model.collectionName.replace(/s$/, "");
            const translateNamespace = `model.${modelName}.${isEdit ? "edit" : "create"}`;
            const cancelReason = ref<any>(null);
            const successReason = ref<any>(null);
            const isNotSubmittable = ref(true);

            const modalOptions = reactive({
                title: `${translateNamespace}.title`,
                props: {
                    model: this,
                    component: ModelComponentDeclaration.default,
                    isSaving: false,
                },
                events: {
                    async "update:isSaving"(isSaving) {
                        if (isSaving) modal.disableClose();
                        else {
                            modal.enableClose();
                        }
                        modalOptions.actions[0].props.disabled = isSaving;
                        modalOptions.actions[1].props.loading = isSaving;
                    },
                    async beforeCreate(entity: any) {
                        if (
                            ModelComponentDeclaration &&
                            ModelComponentDeclaration.onBeforeCreate
                        ) {
                            await ModelComponentDeclaration.onBeforeCreate(entity);
                        }
                    },
                    async created(entity: any) {
                        if (
                            ModelComponentDeclaration &&
                            ModelComponentDeclaration.onCreate
                        )
                            await ModelComponentDeclaration.onCreate(entity);
                        successReason.value = this;
                        modal.close();
                    },
                    async beforeUpdate(entity: any) {
                        if (
                            ModelComponentDeclaration &&
                            ModelComponentDeclaration.onBeforeEdit
                        ) {
                            await ModelComponentDeclaration.onBeforeEdit(entity);
                        }
                    },
                    async updated(entity: any) {
                        if (ModelComponentDeclaration && ModelComponentDeclaration.onEdit)
                            await ModelComponentDeclaration.onEdit(entity);
                        successReason.value = this;
                        modal.close();
                    },
                },
                actions: [
                    {
                        component: VButton,
                        content: app.config.globalProperties.$t(
                            `${translateNamespace}.cancel`
                        ),
                        props: {},
                        events: {
                            click() {
                                cancelReason.value = "closed";
                                modal.close();
                            },
                        },
                    },
                    {
                        component: VButton,
                        content: app.config.globalProperties.$t(
                            `${translateNamespace}.save`
                        ),
                        props: {
                            placeload: false,
                            color: "primary",
                            disabled: isNotSubmittable,
                        },
                        events: {
                            click() {
                                modal.reference.submit();
                            },
                        },
                    },
                ],
            });

            const modal = useModal().createModal(ComponentVModel, modalOptions);

            modal.reference.onChange(() => {
                const fields = modal.reference.getFields();
                // Authorize submit if there are no fields
                if (Object.keys(fields).length <= 0) {
                    isNotSubmittable.value = false;
                    return;
                }
                const hasError = modal.reference.hasError();
                const hasChanged = modal.reference.hasChanged();
                const isProcessing = modal.reference.isProcessing();
                isNotSubmittable.value = !(!hasError && hasChanged && !isProcessing);
            });

            try {
                await new Promise(async (resolve, reject) => {
                    until(cancelReason)
                        .not.toBeNull()
                        .then(() => {
                            reject(cancelReason.value);
                        });

                    until(successReason)
                        .not.toBeNull()
                        .then(() => {
                            resolve(successReason.value);
                        });
                });
            } catch (error) {
                console.error(error);
                this.$reset();
                throw error;
            }
        });

    Model.addMethod("promptAndDelete", async function (this: any) {
        const { app } = await getApp();
        const modal = useModal();
        const translateNamespace = `model.${lowerCaseFirst(Model.name)}`;

        await modal.prompt(
            app.config.globalProperties.$t(translateNamespace + ".remove.title"),
            app.config.globalProperties.$t(translateNamespace + ".remove.subtitle"),
            app.config.globalProperties.$t(translateNamespace + ".remove.message"),
            app.config.globalProperties.$t(translateNamespace + ".remove.cancel"),
            app.config.globalProperties.$t(translateNamespace + ".remove.confirm"),
            "danger"
        );
        await this.$delete();
        if (ModelComponentDeclaration && ModelComponentDeclaration.onDelete) {
            await ModelComponentDeclaration.onDelete(this);
        }
    });
}

export default function () {
    extend(Place, ComponentPlace);
    extend(Map, ComponentMap);
    extend(SocialTemplate, ComponentSocialTemplate);
    extend(SocialProfile);
    extend(Customer, ComponentCustomer);
}
