import { Entity, Var, EntityORM, Input } from "../lib/stores/firestore";
import { Place } from "./place";

@Entity({ collection: "customers" })
export class Customer extends EntityORM {
    @Var(String)
    user?: string | null;

    @Var(Place)
    place: Place | null = null;

    @Var(String)
    @Input("text", { required: true })
    firstName: string = "";

    @Var(String)
    @Input("text", { required: true })
    lastName: string = "";

    @Var(String)
    @Input("email", {
        updateOn: "phoneNumber",
        validate: [
            "email",
            [
                "one-required",
                (value, row) => {
                    console.log(row, "validate row email");
                    return row.phoneNumber || value;
                },
            ],
        ],
    })
    email: string = "";

    @Var(String)
    @Input("phone", {
        updateOn: "email",
        validate: [
            [
                "one-required",
                (value, row) => {
                    console.log(row, "validate row phone");
                    return row.email || value;
                },
            ],
        ],
    })
    phoneNumber: string = "";

    @Var(String)
    @Input("textarea")
    note?: string = "";

    @Var(Array.of(String))
    @Input("select", { multiple: true })
    tags: string[] = [];

    toString() {
        return `${this.firstName || ""} ${this.lastName || ""}`;
    }
}
