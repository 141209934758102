<script lang="ts">
import { Place } from "/@src/models/place";
import { computed, defineComponent, toRefs, ref } from "vue";
import { Collection, Types } from "/@src/models/place";
import { Marker as GoogleMarker, GoogleMap } from "vue3-google-map";
import { geohashForLocation } from "geofire-common/dist/geofire-common";
import { useI18n } from "vue-i18n";
import { GeoPoint } from "firebase/firestore";
import { useEditor, Editor } from "/@src/stores/ckeditor";
import { newDoc } from "/@src/lib/stores/firestore";
import { Menu } from "/@src/models/menu";
import Functions from "/@src/lib/stores/function";
import moment from "moment-with-locales-es6";
import { enumToArray } from "/@src/lib/utils/array";
import { useTranslate } from "/@src/lib/stores/translate";

const { translate } = useTranslate();

export { Collection, Place as Model };

export const DefaultValue = {
    coords: {},
    externalScore: {
        google: {},
        tripadvisor: {},
    },
    owners: [],
    hours: { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] },
};

export async function onCreate(place: any) {
    const menu = newDoc(Menu);
    menu.place = place;
    return menu.$save();
}

export default defineComponent({
    components: { GoogleMap, GoogleMarker },
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { t } = useI18n();
        const editor = useEditor();
        const { form: place } = toRefs(props);

        const query = computed({
            get() {
                if (!place.value.coords) return { text: "" };
                return {
                    latitude: place.value.coords.latitude,
                    longitude: place.value.coords.longitude,
                    text: place.value.location || "",
                };
            },
            set(value) {
                place.value.location = value.text;
                updatePosition(value.latitude, value.longitude);
            },
        });
        const weekDays = [
            moment().day(0),
            moment().day(1),
            moment().day(2),
            moment().day(3),
            moment().day(4),
            moment().day(5),
            moment().day(6),
        ];

        navigator.geolocation.getCurrentPosition((position) => {
            updatePosition(position.coords.latitude, position.coords.longitude);
        });

        const usersOptions = ref(<any[]>[]);

        Functions.getPublicCustomers().then((users: any[]) => {
            usersOptions.value = users.map((user) => {
                return {
                    label: user.displayName + (user.email ? ` (${user.email})` : ""),
                    value: user.uid,
                };
            });
        });

        function updatePositionFromMarker(marker: typeof GoogleMarker) {
            return updatePosition(marker.latLng.lat(), marker.latLng.lng());
        }

        async function updatePosition(latitude: number, longitude: number) {
            place.value.coords = new GeoPoint(latitude, longitude);
            place.value.geohash = geohashForLocation([
                place.value.coords.latitude,
                place.value.coords.longitude,
            ]);
            console.log(place.value);
        }

        function getHourMaxLength() {
            let maxLength = 0;
            if (place.value.hours)
                Object.keys(place.value.hours).forEach((h) => {
                    if (place.value.hours[h].length > maxLength)
                        maxLength = place.value.hours[h].length;
                });
            return maxLength;
        }
        return {
            t,
            translate,
            place,
            editor,
            getHourMaxLength,
            updatePositionFromMarker,
            usersOptions,
            weekDays,
            query,
            Editor,
            Types,
            enumToArray,
        };
    },
});
</script>

<template>
    <VFieldModel v-model="place" property="name"></VFieldModel>
    <VFieldModel v-model="place" property="enableOnFinder"></VFieldModel>
    <VFieldModel v-model="place" property="picture"></VFieldModel>
    <VField label=".coords.label">
        <VControl>
            <GoogleMap
                api-key="AIzaSyCSNoCNjpCRsVmiylytuKgHwKRmQelAfig"
                style="width: 100%; height: 500px"
                :center="{
                    lat: place.coords ? place.coords.latitude : 48.9009945,
                    lng: place.coords ? place.coords.longitude : 2.299859,
                }"
                :zoom="15"
            >
                <GoogleMarker
                    :options="{
                        draggable: true,
                        position: {
                            lat: place.coords ? place.coords.latitude : 48.9009945,
                            lng: place.coords ? place.coords.longitude : 2.299859,
                        },
                    }"
                    @dragend="updatePositionFromMarker"
                />
            </GoogleMap>
        </VControl>
    </VField>
    <VField label=".location.label">
        <VControl>
            <VSearchPlace v-model="query" type="text" class="input" />
        </VControl>
    </VField>
    <VField label=".externalScore.google.link">
        <VControl>
            <input v-model="place.externalScore.google.link" type="text" class="input" />
        </VControl>
    </VField>
    <VField label=".externalScore.google.score">
        <VControl>
            <input
                v-model="place.externalScore.google.score"
                type="number"
                class="input"
                min="0"
                max="5"
                step="0.1"
            />
        </VControl>
    </VField>
    <VField label=".externalScore.google.numberOfReviews">
        <VControl>
            <input
                v-model="place.externalScore.google.numberOfReviews"
                type="number"
                class="input"
                min="0"
                step="1"
            />
        </VControl>
    </VField>
    <VField label=".externalScore.tripadvisor.link">
        <VControl>
            <input
                v-model="place.externalScore.tripadvisor.link"
                type="text"
                class="input"
            />
        </VControl>
    </VField>
    <VField label=".externalScore.tripadvisor.score">
        <VControl>
            <input
                v-model="place.externalScore.tripadvisor.score"
                type="number"
                class="input"
                min="0"
                max="5"
                step="0.1"
            />
        </VControl>
    </VField>
    <VField label=".externalScore.tripadvisor.numberOfReviews">
        <VControl>
            <input
                v-model="place.externalScore.tripadvisor.numberOfReviews"
                type="number"
                class="input"
                min="0"
                step="1"
            />
        </VControl>
    </VField>
    <VField label=".score">
        <VControl>
            <input
                v-model="place.score"
                type="number"
                class="input"
                min="0"
                max="3"
                step="1"
            />
        </VControl>
    </VField>
    <VField label=".scoreMessage">
        <VControl>
            <ckeditor
                v-model="place.scoreMessage"
                :editor="Editor"
                :config="editor.Config"
            ></ckeditor>
        </VControl>
    </VField>
    <VField label=".priceScore">
        <VControl>
            <input
                v-model="place.priceScore"
                type="number"
                class="input"
                min="0"
                max="3"
                step="0.5"
            />
        </VControl>
    </VField>
    <VFieldModel v-model="place" property="type"></VFieldModel>
    <VFieldModel
        v-model="place"
        property="owners"
        :options="{
            mode: 'tags',
            searchable: true,
            options: usersOptions,
        }"
    ></VFieldModel>
    <VField label=".hours">
        <VControl>
            <table class="table is-hoverable is-fullwidth">
                <tbody>
                    <tr v-for="weekDay in weekDays" :key="weekDay">
                        <td>{{ weekDay.format("dddd") }}</td>
                        <td
                            v-for="(hour, index) in place.hours[weekDay.day()]"
                            :key="hour"
                        >
                            <VDatePicker
                                v-model="place.hours[weekDay.day()][index]"
                                is-range
                                mode="time"
                                :model-config="{ type: 'string', mask: 'H:mm' }"
                                :popover="{ placement: 'top' }"
                                is24hr
                            >
                                <template #default="{ inputValue, inputEvents }">
                                    <VField addons>
                                        <VControl>
                                            <input
                                                class="input"
                                                :value="inputValue.start"
                                                v-on="inputEvents.start"
                                            />
                                        </VControl>
                                        <VControl>
                                            <VIconButton
                                                disabled
                                                icon="feather:arrow-right"
                                            />
                                        </VControl>
                                        <VControl subcontrol>
                                            <input
                                                class="input"
                                                :value="inputValue.end"
                                                v-on="inputEvents.end"
                                            />
                                        </VControl>
                                        <VControl>
                                            <VIconButton
                                                icon="feather:trash"
                                                color="danger"
                                                @click="
                                                    place.hours[weekDay.day()].splice(
                                                        index,
                                                        1
                                                    )
                                                "
                                            />
                                        </VControl>
                                    </VField>
                                </template>
                            </VDatePicker>
                        </td>
                        <td :colspan="getHourMaxLength() + 1" align="right">
                            <VIconButton
                                icon="fa fa-plus"
                                @click="
                                    place.hours[weekDay.day()]
                                        ? place.hours[weekDay.day()].push({
                                              start: '0:00',
                                              end: '23:59',
                                          })
                                        : (place.hours[weekDay.day()] = [
                                              { start: '0:00', end: '23:59' },
                                          ])
                                "
                            ></VIconButton>
                        </td>
                    </tr>
                </tbody>
            </table>
        </VControl>
    </VField>
    <VFieldModel v-model="place" property="airtableCalendar"></VFieldModel>
    <VFieldModel v-model="place" property="airtablePost"></VFieldModel>
</template>

<style lang="scss"></style>
