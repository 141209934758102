import { Entity, Var, Input } from "/@src/lib/stores/firestore/index";

import { GeoPoint } from "firebase/firestore";

export const Collection = "places";

export enum Types {
    "asian",
    "french",
    "italian",
    "japan",
    "indian",
    "oriental",
    "thai",
    "labanese",
    "chinese",
    "korean",
    "creperie",
    "african",
    "latin",
    "vietnam",
    "american",
    "morroccan",
    "merger",
    "international",
    "mediterranean",
    "seafood",
    "peruvian",
    "brazilian",
    "spanish",
    "hawaien",
    "turkish",
    "algerian",
    "argentine",
    "iranian",
    "savoyard",
    "corsica",
    "creole",
    "island",
    "vegetarian",
    "mexican",
    "ethiopian",
    "syrian",
    "canadian",
    "afghan",
    "south-west-france",
    "grec",
    "vegan",
    "steakhouse",
    "tunisian",
    "cubain",
    "columbian",
    "russian",
    "eastern-europe",
    "cambodian",
    "israelian",
    "swiss",
    "alsacian",
    "portuguese",
    "britain",
    "german",
    "basque",
}

export class ExternalScores {
    google: ExternalScore = new ExternalScore();
    tripadvisor: ExternalScore = new ExternalScore();
}

export class ExternalScore {
    link: string = "";
    score: number = 0;
    numberOfReviews: number = 0;
}

export class Hours extends Array<Hour> {}

export class Hour extends Array<Number> {}

@Entity({ collection: "places" })
export class Place {
    @Var(String)
    @Input("text", { required: true })
    name: string = "";

    @Var(Boolean)
    @Input("checkbox")
    enableOnFinder: boolean = false;

    @Var(GeoPoint)
    @Input("map")
    coords: GeoPoint = new GeoPoint(48.9009945, 2.299859);

    @Var(String)
    geohash: string | null = null;

    @Var(String)
    @Input("location", { required: true })
    location: string = "";

    @Var(String)
    @Input("file")
    picture: string | null = null;

    @Var(Array.of(String))
    @Input("select", { multiple: true, required: true })
    owners: string[] = [];

    @Var(ExternalScores)
    externalScore: ExternalScores = new ExternalScores();

    @Var(Number)
    score: number = 0;

    @Var(String)
    scoreMessage: string = "";

    @Var(Number)
    priceScore: number = 0;

    @Var(Types)
    @Input("select", { required: true, options: Types })
    type: Types | undefined;

    @Var(Hours)
    @Input(Array.of(Hour), { canAdd: false })
    hours: Hours[] = [[], [], [], [], [], [], []];

    @Var(String)
    @Input("text")
    airtableCalendar?: string;

    @Var(String)
    @Input("text")
    airtablePost?: string;
}
