import { Entity, Var } from "../lib/stores/firestore";
import { Place } from "./place";

export enum SocialProfileType {
    "facebook",
    "instagram",
    "twitter",
}

@Entity({ collection: "socialProfiles" })
export class SocialProfile {
    @Var(String)
    name: string = "";

    @Var(SocialProfileType)
    network: SocialProfileType | null = null;

    @Var(Place)
    place: Place | null = null;

    @Var(Object)
    tokens: any = {};

    @Var(String)
    uid: string = "";
}
