import "/@src/lib/external/ckeditor5-build-balloon";

import { defineStore } from "pinia";
import { getFirestore, getDocs, query, where, collection } from "firebase/firestore";
import { usePlaceManager } from "/@src/stores/placeManager";
import { watch } from "vue";

function MentionCustomization(editor) {
    // The upcast converter will convert view <a class="mention" href="" data-user-id="">
    // elements to the model 'mention' text attribute.
    editor.conversion.for("upcast").elementToAttribute({
        view: {
            name: "a",
            key: "data-mention",
            classes: "mention",
            attributes: {
                "data-product-id": true,
                "data-category-id": true,
            },
        },
        model: {
            key: "mention",
            value: (viewItem) => {
                // The mention feature expects that the mention attribute value
                // in the model is a plain object with a set of additional attributes.
                // In order to create a proper object use the toMentionAttribute() helper method:
                const mentionAttribute = editor.plugins
                    .get("Mention")
                    .toMentionAttribute(viewItem, {
                        // Add any other properties that you need.
                        productId: viewItem.getAttribute("data-product-id"),
                        categoryId: viewItem.getAttribute("data-category-id"),
                    });

                return mentionAttribute;
            },
        },
        converterPriority: "high",
    });

    // Downcast the model 'mention' text attribute to a view <a> element.
    editor.conversion.for("downcast").attributeToElement({
        model: "mention",
        view: (modelAttributeValue, { writer }) => {
            // Do not convert empty attributes (lack of value means no mention).
            if (!modelAttributeValue) {
                return;
            }

            return writer.createAttributeElement(
                "a",
                {
                    class: "mention",
                    "data-mention": modelAttributeValue.id,
                    "data-product-id": modelAttributeValue.productId,
                    "data-category-id": modelAttributeValue.categoryId,
                },
                {
                    // Make mention attribute to be wrapped by other attribute elements.
                    priority: 20,
                    // Prevent merging mentions together.
                    id: modelAttributeValue.uid,
                }
            );
        },
        converterPriority: "high",
    });
}

export const useEditor = defineStore("editor", () => {
    let products = [];

    const placeManager = usePlaceManager();
    const firestore = getFirestore();

    watch(() => placeManager.current, updateProducts);
    updateProducts(placeManager.current);

    async function updateProducts(place) {
        if (!place) return;
        const productsQuery = query(
            collection(firestore, "menuProducts"),
            where("place", "==", place.$getID())
        );
        const productQuerySnapshot = await getDocs(productsQuery);
        products = productQuerySnapshot.docs.map((doc) => {
            console.log(doc);
            const product = doc.data();
            return {
                productId: doc.id,
                categoryId: product.category,
                data: product,
                name: `@${product.name}`,
                id: `@${product.name}`,
            };
        });
        console.log(products);
    }

    function getFeedItems(searchText) {
        searchText = searchText.trim().toLowerCase();
        return products.filter((product) => {
            if (!product.data.name) return;
            return product.data.name.toLowerCase().includes(searchText);
        });
    }

    return {
        Config: {
            toolbar: [
                "bold",
                "italic",
                "underline",
                "|",
                "emoji",
                "|",
                "link",
                "|",
                "undo",
                "redo",
            ],
            extraPlugins: [MentionCustomization],
            mention: {
                feeds: [
                    {
                        marker: "@",
                        feed: getFeedItems,
                        minimumCharacters: 1,
                    },
                ],
            },
        },
    };
});

export const Editor = window.BalloonEditor;
