<script lang="ts">
import { usePlaceManager } from "/@src/stores/placeManager";
import { Map } from "/@src/models/map";
import { toRefs, defineComponent } from "vue";
import { useCustomerTags } from "/@src/stores/customer/tag";

export { Map as Model };

export function onBeforeCreate(entity: Map) {
    const placeManager: any = usePlaceManager();
    if (!placeManager.current) throw new Error("No place");
    entity.place = placeManager.current;
}

export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { form: customer } = toRefs(props);
        const tagsOptions = useCustomerTags();
        return { customer, tagsOptions };
    },
});
</script>
<template>
    <div class="columns is-multiline">
        <div class="column is-6">
            <VFieldModel
                v-model="customer"
                property="firstName"
                icon="feather:user"
            ></VFieldModel>
        </div>
        <div class="column is-6">
            <VFieldModel
                v-model="customer"
                property="lastName"
                icon="feather:user"
            ></VFieldModel>
        </div>

        <div class="column is-12">
            <VFieldModel
                v-model="customer"
                property="email"
                icon="feather:mail"
            ></VFieldModel>
        </div>
        <div class="column is-12">
            <VFieldModel
                v-model="customer"
                icon="feather:phone"
                property="phoneNumber"
            ></VFieldModel>
        </div>
        <div class="column is-12">
            <VFieldModel
                v-model="customer"
                property="note"
                icon="feather:clipboard"
            ></VFieldModel>
        </div>
        <div class="column is-12">
            <VFieldModel
                v-model="customer"
                icon="feather:more-horizontal"
                property="tags"
                :options="{
                    mode: 'tags',
                    searchable: true,
                    createTag: true,
                    options: tagsOptions,
                    noOptionsText: 'Aucun tag',
                    noResultsText: 'Aucun tag',
                }"
            >
                <template #tag="{ option, handleTagRemove, disabled }">
                    <div
                        class="multiselect-tag"
                        :style="{
                            'background-color': option.color,
                            color: option.textColor,
                        }"
                    >
                        {{ option.label }}
                        <span
                            v-if="!disabled"
                            class="multiselect-tag-remove"
                            @click="handleTagRemove(option, $event)"
                            @keydown.q="handleTagRemove(option, $event)"
                        >
                            <span class="multiselect-tag-remove-icon"></span>
                        </span>
                    </div>
                </template>
                <template #option="{ option }">
                    <div
                        class="multiselect-tag"
                        :style="{
                            'background-color': option.color,
                            color: option.textColor,
                        }"
                    >
                        {{ option.label }}
                        <span class="multiselect-tag-remove"> </span>
                    </div>
                </template>
            </VFieldModel>
        </div>
    </div>
</template>
