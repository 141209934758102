import { Entity, Var } from "../lib/stores/firestore";
import type { SocialProfileType } from "./socialProfile";

export enum SocialTemplateType {
    "dish",
    "survey",
    "influence",
    "quote",
    "engaging-question",
    "reservation-objective",
    "ask-me-anything",
    "magic-question",
    "viral",
    "backstage",
    "opinion",
    "acknowledgement",
    "mention",
    "motivation",
    "event",
    "recommendation",
    "kindness",
    "learning",
}

@Entity({ collection: "socialTemplates" })
export class SocialTemplate {
    @Var(Array.of(String))
    pictures: string[] = [];

    @Var(String)
    caption: string = "";

    @Var(SocialTemplateType)
    network: SocialProfileType | null = null;

    @Var(SocialTemplateType)
    type: SocialTemplateType | null = null;
}
