import { useCssVar } from "@vueuse/core";

export function useCustomerTags() {
    const purpleColor = useCssVar("--purple");
    const lightColor = useCssVar("--light-grey");
    return [{ value: "vip", label: "VIP", color: purpleColor, textColor: lightColor }];
}

export function getCustomerTagByValue(value: string) {
    const tags = useCustomerTags();
    const findedTag = tags.find((tag) => tag.value === value);
    if (!findedTag) {
        return {};
    }
    return findedTag;
}
