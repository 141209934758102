import { Entity, Input, Var } from "../lib/stores/firestore";
import { Place } from "./place";

@Entity({ collection: "maps" })
export class Map {
    @Var(String)
    @Input("text", { required: true })
    name: string = "";

    @Var(Place)
    place: Place | null = null;
}
