<script lang="ts">
import { toRefs, defineComponent } from "vue";
import { SocialProfileType } from "/@src/models/socialProfile";
import { SocialTemplateType } from "/@src/models/socialTemplate";
import { enumToArray } from "/@src/lib/utils/array";
import { useTranslate } from "/@src/lib/stores/translate";

export const DefaultValue = {
    pictures: [],
};

export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { translate } = useTranslate();
        const { form: socialTemplate } = toRefs(props);
        return {
            translate,
            socialTemplate,
            SocialProfileType,
            SocialTemplateType,
            enumToArray,
        };
    },
});
</script>

<template>
    <VField label=".type">
        <VControl>
            <VSelect v-model="socialTemplate.type">
                <VOption
                    v-for="type in enumToArray(SocialTemplateType)"
                    :key="type"
                    :value="type"
                    ><Translate>{{
                        "model.socialTemplate.types." + type
                    }}</Translate></VOption
                >
            </VSelect>
        </VControl>
    </VField>
    <VField label=".network">
        <VControl>
            <VSelect v-model="socialTemplate.network">
                <VOption
                    v-for="network in enumToArray(SocialProfileType)"
                    :key="network"
                    :value="network"
                    ><Translate>{{ ".networks." + network }}</Translate></VOption
                >
            </VSelect>
        </VControl>
    </VField>
    <VField label=".picture">
        <VControl>
            <VFile
                v-model="socialTemplate.pictures"
                multiple
                storage-path="socialTemplate"
            />
        </VControl>
    </VField>
    <VField label=".caption">
        <VControl>
            <textarea v-model="socialTemplate.caption" class="input"></textarea>
        </VControl>
    </VField>
</template>
